<template>
<div class="wrap3">
    <div class="mg-auto" style="width: 1850px;">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> 상담사 수정</h2>
            <input type="hidden" name="_token" id="_token" value="iu9xcVuFZ79uA3zQNaVTassFPxCio38exMLm1RxR">
            <input type="hidden" name="center_id" id="center_id" value="1">
            <input type="hidden" name="role_id" id="role_id" value="1"><!-- 상담사 -->
            <input type="hidden" name="callback" id="callback" value="">

            <div class="con">
                <div class="con_table4">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th style="width:78px;">ID <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="id" disabled class="ml-20" style="width:200px;" id="id" name="id" value=""> <br>
                                    <!-- <span class="ml-20"> 영문 대/소문자 + 숫자포함 6~40자</span> -->
                                </td>
                                <th>비밀번호 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="pwd" class="ml-20" style="width:200px;" id="password" name="password" value=""> <br>
                                    <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                                </td>
                            </tr>
                            <tr>
                                <th>성명 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="name" class="ml-20" style="width:200px;" id="name" name="name" value="">
                                </td>
                                <th>성별 <span class="red">*</span></th>
                                <td class="left">
                                    <select v-model="gender" class="ml-20 w-60px">
                                        <option value="남자">남자</option>
                                        <option value="여자">여자</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰 번호 <span class="red">*</span></th>
                                <td class="left">
                                    <select class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select> -
                                    <input type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value=""> -
                                    <input type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="">

                                </td>
                                <th>이메일 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="email1" style="width:120px;" class="ml-20" name="email1" id="email1" value="">@
                                    <input type="text" v-model="email2" style="width:80px;" class="mg5" id="email2" name="email2" value="">
                                    <select v-model="email3" name="email3" id="email3" @change="email2 = email3">
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="naver.com">naver.com</option>
                                        <option value="nate.com">nate.com</option>
                                        <option value="nate.com">daum.net</option>
                                        <option value="" selected="selected">직접입력</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일 <span class="red">*</span></th>
                                <td class="left">
                                    <select name="birth-yyyy" v-model="birthYY" class="ml-20" id="birth-yyyy">
                                        <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                                    </select>
                                    <select name="birth-mm" v-model="birthMM" class="ml-10" id="birth-mm">
                                        <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                                    </select>
                                    <select name="birth-dd" v-model="birthDD" class="ml-10" id="birth-dd">
                                        <option v-for="index in 31" :key="index" :value="index">{{index}}일</option>
                                    </select>
                                </td>
                                <th>최종학력 <span class="red">*</span></th>
                                <td class="left">
                                    <select name="birth-dd" v-model="education" class="ml-20 mr-10" id="birth-dd">
                                        <option value="중학교">중졸</option>
                                        <option value="고등학교" selected>고졸</option>
                                        <option value="대학졸업(2,3년)">대졸(2~3년)</option>
                                        <option value="대학졸업(4년)">대졸(4년)</option>
                                        <option value="석사졸업">석사</option>
                                        <option value="박사졸업">박사</option>
                                    </select>
                                    학교/전공 :
                                    <input type="text" v-model="educationInfo" style="width:120px;" class="ml-10" name="email1" id="email1" value="" />
                                </td>
                            </tr>
                            <tr>
                                <th>경력 <span class="red">*</span></th>
                                <td class="left">
                                    <select name="working-day" v-model="career" class="ml-20" id="working-day">
                                        <option v-for="index in 40" :key="index" :value="index">{{index}}년</option>
                                        <option :value="41">40년이상</option>
                                    </select>
                                </td>
                                <th>자격증 <span class="red">*</span></th>
                                <td class="left"><input type="hidden" id="idx_file_cer" name="idx_file_cer" value="0">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.certFile.click()">파일 선택</button>
                                    <input v-show="false" ref="certFile" type="file" @change="uploadCert($event)" /><br>
                                    <!-- <span v-for="(item, index) of certFileNames" :key="index" :class="{'ml-20': !item.delete}" style="cursor:pointer" @click="detailFile()"> -->
                                    <span v-for="(item, index) of certFileNames" :key="index" :class="{'ml-20': !item.delete}">
                                        <span v-if="!item.delete">{{item.name}} <span class="underline pointer" @click="removeCertFile(item.name, item.idx, index)">삭제</span><br></span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 100px;">한줄소개 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="introduce" class="ml-20 w-98per" id="introduce" name="introduce" value="">
                                </td>
                            </tr>                            <tr>
                                <th style="width: 100px;">사진 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.profileFile.click()">파일 선택</button>
                                    <input v-show="false" ref="profileFile" type="file" @change="uploadProfile($event)" /><br>
                                    <span v-if="photoName !== '' && photoUrl" class="ml-20">{{photoName}} <span class="underline pointer" @click="removeProfileFile()">삭제</span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                              <th style="width: 100px;">기타정보</th>
                              <td class="left" colspan="3">
                                <textarea type="text" v-model="memo" class="ml-20 w-98per h-100px" id="memo" name="memo" value="" />
                              </td>
                            </tr>
                            <tr>
                                <th style="width:100px;">재직현황</th>
                                <td class="left">
                                    <select v-model="duty" class="ml-20 w-100px">
                                        <option value="재직">재직</option>
                                        <option value="휴직">휴직</option>
                                        <option value="퇴직">퇴직</option>
                                    </select>
                                </td>
                                <th style="width:100px;">센터 <span class="red">*</span></th>
                                <td class="left">
                                    <select v-model="idxCrmCenter" class="ml-20 w-120px" :disabled="(auth === 'MASTER' || auth === 'ADMIN') ? false : true">
                                        <option value="-1">센터선택</option>
                                        <option value="0">본원센터</option>
                                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>전문분야</th>
                                <td colspan="3" class="left" style="width: 485px;">
                                    <select v-model="idxCrmMetaPro" class="ml-20 w-100px">
                                        <option value="-1">전문분야</option>
                                        <option v-for="(item, index) of proList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                                <!--
                                <th>회기당요율</th>
                                <td class="left">
                                    <span v-if="userPayVO" class="bold mr-20">{{ userPayVO.pay }}%</span>
                                    <span v-if="userPayVO">{{ userPayVO.sdate }} ~ {{ userPayVO.edate }}</span>
                                </td>
                                -->
                            </tr>
                            <tr>
                                <th>근무요일 <span class="red">*</span><br><input type="checkbox" @click="checkAll()" /></th>
                                <td class="left workDayTd" colspan="3">
                                    <table class="default_table mt-20 ml-30 mb-20">
                                        <tbody>
                                            <tr>
                                                <th class="reset w-0px"></th>
                                                <th class="w-40px">09:00</th>
                                                <th class="w-40px">09:30</th>
                                                <th class="w-40px">10:00</th>
                                                <th class="w-40px">10:30</th>
                                                <th class="w-40px">11:00</th>
                                                <th class="w-40px">11:30</th>
                                                <th class="w-40px">12:00</th>
                                                <th class="w-40px">12:30</th>
                                                <th class="w-40px">13:00</th>
                                                <th class="w-40px">13:30</th>
                                                <th class="w-40px">14:00</th>
                                                <th class="w-40px">14:30</th>
                                                <th class="w-40px">15:00</th>
                                                <th class="w-40px">15:30</th>
                                                <th class="w-40px">16:00</th>
                                                <th class="w-40px">16:30</th>
                                                <th class="w-40px">17:00</th>
                                                <th class="w-40px">17:30</th>
                                                <th class="w-40px">18:00</th>
                                                <th class="w-40px">18:30</th>
                                                <th class="w-40px">19:00</th>
                                                <th class="w-40px">19:30</th>
                                                <th class="w-40px">20:00</th>
                                                <th class="w-40px">20:30</th>
                                                <th class="w-40px">21:00</th>
                                                <th class="w-40px">21:30</th>
                                                <th class="w-40px">22:00</th>
                                                <th class="w-40px">22:30</th>
                                                <th class="w-40px">23:00</th>
                                                
                                            </tr>
                                            <tr v-for="(item, idx) of workTimeList" :key="idx">
                                                <td>
                                                    {{item.day}}
                                                </td>
                                                <td v-for="(subItem, index) of item.table" :key="index"><input type="checkbox" v-model="subItem.use" /> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle pointer" @click="putUserInfo()">수정</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    IDFormatCheck,
    PWFormatCheck,
    EmptyCheck,
} from '@/utils/validation.js'
import {
    GetUrlParams
} from '@/utils/common.js'

export default {
    data: () => ({
        id: '', // 아이디
        pwd: '', // 비밀번호,
        name: '', // 이름
        gender: '남자', // 성별
        phone: '',
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        email: '', // 이메일
        email1: '', // 이메일 앞자리
        email2: '', // 이메일 뒷자리
        email3: '', // 이메일 도메인 선택시
        birthYY: 2020, // 생년월일 YYYY
        birthMM: 1, // 생년월일 MM
        birthDD: 1, // 생년월일 DD
        birthTempMM: 1, // 생년월일 MM
        birthTempDD: 1, // 생년월일 DD
        birth: '', // YYYY-MM-DD
        education: '고등학교', // 최종학력
        educationInfo: '', // 학교/전공
        career: 1, // 경력
        introduce: '', // 한줄소개
        duty: '재직', // 재직현황
        idxCrmCenter: -1, // center idx
        idxCrmMetaPro: -1, // 전문분야
        userPayVO: {
            pay: 0, // 회기당 급여
            sdate: '', //회기 시작일
            edate: '', //회기종료일
        },
        idx: -1, // 상담사 고유 idx

        profileFiles: [],

        workTimeList: [], // 근무요일 타임테이블

        certFiles: [], // 자격증 파일 리스트 (최대 5개)
        certFileNames: [], // 자격증 파일명 리스트
        profileFile: '', // 프로필 사진 파일
        photoName: '', // 프로필 사진 파일명
        photoUrl: '', // 프로필 사진 url
        autoSearchCenter: '', // 센터 자동완성 검색
        isAutoCenter: false, // 센터 자동완성 on/off
        center: '', // 센터명
        centerList: [], // 센터명 리스트 (자동완성)
        proList: [], // 전문분야
        permission: '',
        memo: '', // 기타정보
        auth : localStorage.getItem('auth') || '',
        isChecked: false, //전체체크여부
    }),

    /* computed: {
        filterList() {
            const str = this.autoSearchCenter
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCenter = true
                var filterNames = []
                filterNames = this.centerList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCenter = false
                }
            } else {
                this.isAutoCenter = false
            }
        }
    }, */

    mounted() {
        this.setWorkTimeList()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getUserInfo(this.idx)
        this.getCenterListAll()
        this.getMetaList()
    },

    methods: {

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.proList = res.data.proList
                    }
                })
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 상담사 데이터 api 호출
        getUserInfo(idx) {
            var params = {
                idx: idx
            }

            this.axios.get(`/api/v1/user/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        for (var item of Object.keys(res.data.userVO)) {
                            if (typeof this[item] !== 'undefined') {
                                if (item === 'workTimeList' ) {
                                    continue
                                }

                                // 생년월일 데이터 매핑
                                if (item === 'birth') {
                                    var birth = res.data.userVO[item].split('-')
                                    this.birthYY = birth[0]
                                    this.birthMM = birth[1] * 1 // string형 숫자형으로 변환 해주기 위한 곱셈
                                    this.birthDD = birth[2] * 1
                                    continue
                                }

                                // 이메일 매핑
                                if (item === 'email') {
                                    if (res.data.userVO[item].includes('@')) {
                                        var email = res.data.userVO[item].split('@')
                                        this.email1 = email[0]
                                        this.email2 = email[1]
                                    }
                                    continue
                                }

                                // 휴대폰 번호 매핑
                                if (item === 'phone') {
                                    var phone = res.data.userVO[item].split('-')
                                    if (phone.length === 3) {
                                        this.phone1 = phone[0]
                                        this.phone2 = phone[1]
                                        this.phone3 = phone[2]
                                    }
                                    continue
                                }

                                // 사진 매핑
                                if (item === 'photoUrl') {
                                    if (res.data.userVO[item] !== null && res.data.userVO[item].includes('https://kr.object.ncloudstorage.com')) {
                                        this[item] = res.data.userVO[item]
                                        this.photoName = res.data.userVO['photoName']
                                    }
                                    continue
                                }

                                this[item] = res.data.userVO[item]
                            }
                        }

                        this.idxCrmCenter = res.data.userVO.idxCrmCenter
                        this.idxCrmMetaPro = res.data.userVO.idxCrmMetaPro
                        this.userPayVO = res.dta.userVO.userPayVO

                        if (res.data.userVO.duty === null) {
                            this.duty = ''
                        } else {
                            this.duty = res.data.userVO.duty
                        }

                        // 자격증 매핑
                        for (var certi of res.data.certiList) {
                            this.certFileNames.push({
                                name: certi.certificate_name,
                                certificate_url: certi.certificate_url,
                                delete: false,
                                idx: certi.idx
                            })
                        }

                        // 근무요일 테이블 매핑
                        for (var resWorkTime of res.data.workTimeList) {
                            for (var dataWorkTime of this.workTimeList) {
                                if (resWorkTime.day === dataWorkTime.day) {
                                    for (var partTime of dataWorkTime.table) {
                                        if (resWorkTime.time === partTime.time) {
                                            partTime.use = true
                                        }
                                    }
                                }
                            }
                        }

                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        // 사진 삭제
        removeProfileFile() {
            this.profileFile = ''
            this.photoName = ''
            this.photoUrl = ''
        },

        // 자격증 삭제
        removeCertFile(name, index, certIdx) {
            for (var [idx, file] of this.certFiles.entries()) {
                if (file.name === name) {
                    this.certFiles.splice(idx, 1)
                }
            }

            this.certFileNames[certIdx].delete = true
            this.certFileNames[certIdx].certificate_url = ''
            this.certFileNames[certIdx].name = ''

            // this.certFileNames.splice(certIdx, 1)

            // for (var [idx, cFile] of this.certFileNames.entries()) {

            //     if (index !== -1) {
            //         if (cFile.idx === index) {
            //             this.certFileNames.splice(idx, 1)
            //             break
            //         }
            //     } else {

            //     }

            //     for (var i = 1; i <= 3; i++) {
            //         if (cFile.name === this['certFileName' + i.toString()]) {
            //             this['centerPhotoName' + (i).toString()] = ''
            //             this['centerPhotoUrl' + (i).toString()] = ''
            //             check = true
            //             break
            //         }
            //     }
            //     if (check) {
            //         break
            //     }
            // }

            // var check = false

            // for (var [idx, cFile] of this.certFileNames.entries()) {

            //     for (var i = 1; i <= 3; i++) {
            //         if (cFile.name === this['certFileName' + i.toString()]) {
            //             this['centerPhotoName' + (i).toString()] = ''
            //             this['centerPhotoUrl' + (i).toString()] = ''
            //             check = true
            //             break
            //         }
            //     }
            //     if (check) {
            //         break
            //     }
            // }

            // this.centerFiles.splice(index, 1)
        },

        // 자격증 파일 업로드 (최대 5개까지)
        uploadCert(event) {
            // 포맷 검사
            var allowFormat = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'PDF', 'JPG', 'JPEG', 'PNG', 'GIF']
            var checked = false
            var count = 0

            for (var item of this.certFileNames) {
                if (!item.delete) {
                    count += 1
                }
            }

            if (count >= 5) {
                alert('자격증은 최대 5개까지 업로드 가능합니다.')
                this.$refs.certFile.value = null;
                return false
            } else {

                for (var item of allowFormat) {
                    if (event.target.files[0].name.includes(item)) {
                        checked = true
                        break
                    } else {
                        continue
                    }
                }

                if (checked) {
                    // 용량 검사
                    if (event.target.files[0].size > 1048576 * 2) {
                        alert('2MB 이하만 업로드 가능합니다.')
                        this.$refs.certFile.value = ''
                        this.$refs.certFile.value = null;
                        return false
                    }
                    this.certFiles.push(event.target.files[0])
                    if (this.certFileNames.length < 5) {
                        this.certFileNames.push({
                            name: event.target.files[0].name,
                            certificate_url: '',
                            delete: false,
                            idx: -1
                        })
                    } else {
                        for (var [index, item] of this.certFileNames.entries()) {
                            if (item.delete) {
                                item.delete = false
                                item.name = event.target.files[0].name
                                this.$refs.certFile.value = null;
                                break
                            }
                        }
                    }

                    this.$refs.certFile.value = null;
                } else {
                    alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                    this.$refs.certFile.value = null;
                    return false
                }
            }
        },

        // 자격증 파일 업로드 (최대 5개까지)
        /* uploadCert(event) {
            var count = 0

            for (var item of this.certFileNames) {
                if (!item.delete) {
                    count += 1
                }
            }

            if (count >= 5) {
                alert('자격증은 최대 5개까지 업로드 가능합니다.')
                return false
            } else {
                this.certFiles.push(event.target.files[0])
                if (this.certFileNames.length < 5) {
                    this.certFileNames.push({
                        name: event.target.files[0].name,
                        certificate_url: '',
                        delete: false,
                        idx: -1
                    })
                } else {
                    for (var [index, item] of this.certFileNames.entries()) {
                        if (item.delete) {
                            item.delete = false
                            item.name = event.target.files[0].name
                            break
                        }
                    }
                }

            }

        }, */

        // 프로필 사진 업로드
        uploadProfile(event) {
            // 포맷 검사
            var allowFormat = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'PDF', 'JPG', 'JPEG', 'PNG', 'GIF']
            var checked = false
            for (var item of allowFormat) {
                if (event.target.files[0].name.includes(item)) {
                    checked = true
                    break
                } else {
                    continue
                }
            }

            if (checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576) {
                    alert('1MB 이하만 업로드 가능합니다.')
                    this.$refs.profileFile.value = ''
                    return false
                }

                this.profileFile = event.target.files[0]
                this.photoName = this.profileFile.name;
                this.photoUrl='temp';

                this.$refs.profileFile.value = ''
            } else {
                alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                this.$refs.profileFile.value = ''
                return false
            }
        },
        /*
        // 센터 자동완성 입력 감지
        watchEvent(e) {
            this.autoSearchCenter = e.target.value
        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str) {
            this.isAutoCenter = false
            this.center = str
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str) {
            if (this.isAutoCenter === true) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }
                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }
                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str);
                }
            }
        }, */

       

        // 등록요청
        putUserInfo() {
            // 아이디 검사
            if (!EmptyCheck(this.id, '아이디를')) {
                return false
            } else {
                if (!IDFormatCheck(this.id)) {
                    return false
                }
            }

            // 비밀번호 검사
            if (!EmptyCheck(this.pwd, '암호를')) {
                return false
            } else {
                if (!PWFormatCheck(this.pwd)) {
                    return false
                }
            }

            // 이름 검사
            if (!EmptyCheck(this.name, '성명을')) {
                return false
            }

            if (!EmptyCheck(this.gender, '성별을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            var phone = '';
            if (this.phone2 !== '' && this.phone3 !== '') {
                phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                phone = ''
            }

            if (phone === '') {
                alert('핸드폰정보를 입력해주세요')
                return false
            }

            // 이메일을 전부 입력한경우에만 이메일 포맷으로 파싱
            if (this.email1 !== '' && this.email2 !== '') {
                var email = this.email1 + '@' + this.email2
            } else {
                var email = ''
            }

            if (email === '') {
                alert('이메일을 입력해주세요')
                return false
            }

            // 생년월일 데이터 파싱
            if(this.birthMM < 10) {
                this.birthTempMM = "0" + this.birthMM
            }else{
                this.birthTempMM = this.birthMM
            }

            if(this.birthDD < 10) {
                this.birthTempDD = "0" + this.birthDD
            }else{
                this.birthTempDD = this.birthDD
            }

            var birth = this.birthYY.toString() + '-' + this.birthTempMM.toString() + '-' + this.birthTempDD.toString()
            // alert(this.birthDD);
            // alert(this.birthTempDD);
            if (birth === '') {
                alert('생년월일을 입력해주세요')
                return false
            }

            if (!EmptyCheck(this.educationInfo, '학교/전공을')) {
                return false
            }

            if (this.certFiles.length === 0 && this.certFileNames.length === 0)
            {
                alert('자격증 파일을 첨부하세요')
                return false
            }

            if (!EmptyCheck(this.introduce, '한줄소개를')) {
                return false
            }
            
            if (this.profileFile.length === 0 && this.photoUrl === '') {
                alert('사진 파일을 첨부하세요')
                return false
            }

            if (this.idxCrmCenter === -1) {
                alert('센터를 선택해주세요')
                return false
            }
            // // 자격증 파일 파라미터형태로 변경
            // var certificateList = []
            // for (var cert of this.certFiles) {
            //     certificateList.push({
            //         certificate: cert
            //     })
            // }

            // 근무요일 테이블 파라미터형태로 변경
            var workTimeList = []
            var copyWorkTimeList = JSON.parse(JSON.stringify(this.workTimeList))
            for (var item of copyWorkTimeList) {
                for (var subItem of item.table) {
                    if (subItem.use) {
                        delete subItem['use']
                        workTimeList.push(subItem)
                    }
                }
            }
            if(workTimeList.length === 0) {
                alert('근무시간을 선택해주세요')
                return false
            }

            //파일 업로드
            this.fileUpload(this.idx);
        },

         fileUpload(idxCrmStaff) {
            var params = new FormData()

            // var params = new URLSearchParams()
            params.append('idxCertFile1', -1)
            params.append('idxCertFile2', -1)
            params.append('idxCertFile3', -1)
            params.append('idxCertFile4', -1)
            params.append('idxCertFile5', -1)
            params.append('idxCrmStaff', idxCrmStaff)
            params.append('type', '')
            params.append('type1', '')
            params.append('type2', '')
            params.append('type3', '')
            params.append('type4', '')
            params.append('type5', '')

            // 기본 타입으로 추가해놓기
            params.append('certiFile1', new Blob())
            params.append('certiFile2', new Blob())
            params.append('certiFile3', new Blob())
            params.append('certiFile4', new Blob())
            params.append('certiFile5', new Blob())
            params.append('profileFile', new Blob())

            for (var [certIdx, fileInfo] of this.certFileNames.entries()) {
                if (fileInfo.idx === -1) {
                    for (var [index, file] of this.certFiles.entries()) {
                        if (file.name === fileInfo.name) {
                            params.delete('certiFile' + (certIdx + 1))
                            params.append('certiFile' + (certIdx + 1), file)
                            params.delete('type' + (certIdx + 1))
                            params.append('type' + (certIdx + 1), 'I')
                            break
                        }
                    }
                } else {
                    if (fileInfo.certificate_url === '' && fileInfo.delete === true) {
                        params.delete('idxCertFile' + (certIdx + 1))
                        params.append('idxCertFile' + (certIdx + 1), fileInfo.idx)
                        params.delete('type' + (certIdx + 1))
                        params.append('type' + (certIdx + 1), 'D')
                    } else if (fileInfo.certificate_url === '' && fileInfo.delete === false) {
                        for (var [index, file] of this.certFiles.entries()) {
                            params.delete('idxCertFile' + (certIdx + 1))
                            params.append('idxCertFile' + (certIdx + 1), fileInfo.idx);
                            if(file.name === fileInfo.name) {
                                params.delete('certiFile' + (certIdx + 1))
                                params.append('certiFile' + (certIdx + 1), file)
                            }
                            params.delete('type' + (certIdx + 1))
                            params.append('type' + (certIdx + 1), 'U')
                        }
                    }
                }
            }

            // 프로필 사진이 있는 경우에는 파일 추가
            // 프로필 사진이 없는 경우에는 idx 및 type만 없이 추가
            if (this.profileFile !== '') {
                params.delete('profileFile')
                params.append('profileFile', this.profileFile)
                params.delete('type')
                params.append('type', 'U')
            } else {
                params.delete('type')
                if (this.photoName === '') {
                    params.append('type', 'D')
                }
                params.append('idxProfile', this.idx)
            }

            this.axios.post('/api/v1/user/files', params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.fileList.length>0){
                        this.saveUserInfo(res.data.fileList[0].fileUrl);
                    }
                    else{
                        this.saveUserInfo();
                    }
                })
                .catch(err => {
                    console.log(err)
                })

        },

        saveUserInfo(fileUrl=''){
            // 생년월일 데이터 파싱
            if(this.birthMM < 10) {
                this.birthTempMM = "0" + this.birthMM
            }else{
                this.birthTempMM = this.birthMM
            }

            if(this.birthDD < 10) {
                this.birthTempDD = "0" + this.birthDD
            }else{
                this.birthTempDD = this.birthDD
            }
            var birth = this.birthYY.toString() + '-' + this.birthTempMM.toString() + '-' + this.birthTempDD.toString();

            // 이메일을 전부 입력한경우에만 이메일 포맷으로 파싱
            var email='';
            if (this.email1 !== '' && this.email2 !== '') {
                email = this.email1 + '@' + this.email2
            } else {
                email = ''
            }


            var workTimeList = []
            var copyWorkTimeList = JSON.parse(JSON.stringify(this.workTimeList))
            for (var item of copyWorkTimeList) {
                for (var subItem of item.table) {
                    if (subItem.use) {
                        delete subItem['use']
                        workTimeList.push(subItem)
                    }
                }
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            var phone = '';
            if (this.phone2 !== '' && this.phone3 !== '') {
                phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                phone = ''
            }

             var vo = {
                authority: 'STAFF',
                birth: birth,
                career: this.career,
                duty: this.duty,
                education: this.education,
                educationInfo: this.educationInfo,
                email: email,
                id: this.id,
                idx: this.idx,
                idxCrmCenter: this.idxCrmCenter,
                introduce: this.introduce,
                name: this.name,
                permission: this.permission,
                phone: phone,
                pwd: this.pwd,
                gender: this.gender,
                workTimeList: workTimeList,
                idxCrmMetaPro: this.idxCrmMetaPro,
                memo : this.memo
            }

            // 프로필 사진이 이미 있는 경우 url 및 name 추가
            if (this.photoUrl !== '') {
                vo['photoUrl'] = this.photoUrl
                vo['photoName'] = this.photoName
            }
            
            if(this.photoUrl == 'temp'){
                vo['photoUrl'] = fileUrl;
            }

            this.axios.put(`/api/v1/user/${this.idx}`, vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('수정이 완료되었습니다.');
                        this.$router.push('/origin/counselor_manage');
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 근무요일 기본 테이블구조 변수에 할당
        setWorkTimeList() {
            this.workTimeList = []
            var days = ['월', '화', '수', '목', '금', '토', '일']
            for (var day of days) {
                var tempDayTable = {
                    day: day,
                    table: []
                }
                let time = 9
                for (var index = 0; index < 29; index++) {
                    //time += index
                    var stringTime = ''
                    /* if (time >= 10) {
                        stringTime = time.toString() + ':' + '00'
                    } else {
                        stringTime = '0' + time.toString() + ':' + '00'
                    } */
                    if (time >= 10) {
                        if (index % 2 == 0) {
                            stringTime = time.toString() + ':' + '00'
                        } else {
                            stringTime = time.toString() + ':' + '30'
                            time += 1
                        }
                    } else {
                        if (index % 2 == 0) {
                            stringTime = '0' + time.toString() + ':' + '00'
                        } else {
                            stringTime = '0' + time.toString() + ':' + '30'
                            time += 1
                        }
                    }
                    tempDayTable.table.push({
                        use: false,
                        day: day,
                        time: stringTime
                    })
                }
                this.workTimeList.push(tempDayTable)
            }
        },
        checkAll(){
            this.isChecked = !this.isChecked;
            for(var item of this.workTimeList){
                for (var subItem of item.table) {
                    subItem.use = this.isChecked;
                }
            }
        },
        // 이전 페이지로 이동
        cancel() {
            history.back()
        },

        detailFile() {

        }
    }
}
</script>

<style lang="scss">
#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 27px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}

.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}

.workDayTd{
    overflow-x:scroll;
    max-width:1000px;
}
</style>
